<template>
  <SmoothModal
    :model-value="isOpenProp"
    width="1080px"
    height="534px"
    :has-header="false"
    @update:model-value="closeModal"
  >
    <SimpleTransition :fix-height="552">
      <PlanSelector
        v-if="isSelector"
        :account-types="accountTypes"
        :balance="balance"
        @select-plan="onSelectPlan"
      />

      <SelectedPlan
        v-else
        :selected-plan="selectedPlan"
        :is-upgrade="isUpgrade"
        :is-loading="isPlanChanging"
        @go-back="onGoBack"
        @confirm="changePlan"
      />
    </SimpleTransition>
  </SmoothModal>
</template>

<script>
import { computed, ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import SimpleTransition from '@/components/Animation/SimpleTransition.vue';

import { i18n } from '@/plugins/localization';
import { updateMerchantPlan } from '@/api/merchant';
import { accountTypes } from '@/common/data';
import { emitter } from '@/composables/useBus';
import { useModel } from '@/composables/useModel';
import useNotifications from '@/composables/useNotifications';

import PlanSelector from './components/PlanSelector.vue';
import SelectedPlan from './components/SelectedPlan.vue';

export default {
  name: 'ModalToChangePlan',
  components: {
    SmoothModal,
    PlanSelector,
    SelectedPlan,
    SimpleTransition,
  },
  props: {
    billingInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const { value: isOpenProp } = useModel(props, emit, { prop: 'modelValue' });
    const balance = computed(() => (props.billingInfo?.balanceUsd));
    const isSelector = ref(true);
    const isPlanChanging = ref(false);

    const currentPlan = computed(() => (props.billingInfo?.subscription));

    const selectedPlan = ref(null);

    const needAmountToChangePlan = computed(() => (selectedPlan?.value?.cycle === 'MONTHLY' ? selectedPlan?.value?.plan.perMonth : selectedPlan?.value?.plan?.perYear));

    const isUpgrade = computed(() => {
      const currentPlanWeight = calculatePlanWeight(currentPlan.value.plan?.name, currentPlan.value.cycle);
      const selectedPlanWeight = calculatePlanWeight(selectedPlan?.value?.plan?.title, selectedPlan?.value?.cycle);

      return selectedPlanWeight > currentPlanWeight;
    });

    const calculatePlanWeight = (plan, cycle) => {
      switch (plan) {
        case 'PRO':
          return cycle === 'MONTHLY' ? 1 : 2;
        case 'BUSINESS':
          return cycle === 'MONTHLY' ? 3 : 4;
        case 'BLACK':
          return cycle === 'MONTHLY' ? 5 : 6;
        default:
          return 0;
      }
    };

    const closeModal = () => {
      isOpenProp.value = false;
      isSelector.value = true;
      selectedPlan.value = null;
    };

    const onSelectPlan = async (plan) => {
      selectedPlan.value = plan;
      if (balance.value >= needAmountToChangePlan.value) {
        if (currentPlan.value?.plan?.name === selectedPlan?.value?.plan?.title && currentPlan.value.cycle === selectedPlan?.value?.cycle) {
          const { addNotification } = useNotifications();
          addNotification({ text: t('merchantBilling.notification.selectPlanAlreadyHave'), config: { color: 'error' } });
        } else {
          isSelector.value = false;
        }
      } else {
        const { addNotification } = useNotifications();
        addNotification({ text: t('merchantBilling.notification.selectPlanNotEnoughBalance'), config: { color: 'error' } });
      }
    };

    const changePlan = async () => {
      isPlanChanging.value = true;
      const { isSuccess, successHandler } = await updateMerchantPlan({
        type: selectedPlan?.value?.plan?.title,
        cycle: selectedPlan?.value?.cycle,
      });
      if (isSuccess) {
        successHandler(t('merchantBilling.notification.changeMerchantPlanSuccess'));
        closeModal();
      }
      emitter.emit('update-billing-page');
      isPlanChanging.value = false;
    };

    const onGoBack = () => {
      isSelector.value = true;
      selectedPlan.value = null;
    };

    return {
      isOpenProp,
      closeModal,
      balance,
      accountTypes,
      isSelector,
      selectedPlan,
      onGoBack,
      changePlan,
      isUpgrade,
      isPlanChanging,
      onSelectPlan,
    };
  },
};
</script>
