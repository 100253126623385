import { toValue } from 'vue';
import dayjs from 'dayjs';

import { useFetch } from '@/api';
import { useStickies } from '@/composables/useStickies';

const { store: stickiesStore, active, isStickiesLoaded } = useStickies();

const {
  setHistory,
  setActive,
  setStickiesStartingYear,
  addActive,
  stickiesSelectedYear,
} = stickiesStore;

export const getStickiesHistory = async ({
  page = 1, perPage = 15, year = dayjs().year(),
}) => {
  const { axiosWrapper } = useFetch();

  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/admin/stickies/history',
    params: {
      isFreshResponse: true,
      page,
      perPage,
      year,
    },
  });

  if (isSuccess) {
    const { data: history, ...pagination } = data;
    setHistory(history, pagination);
  }

  return { isSuccess };
};

export const getActiveStickies = async () => {
  isStickiesLoaded.value = false;
  const { axiosWrapper } = useFetch();

  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/admin/stickies/active',
  });

  if (isSuccess) {
    setActive(data);
  }

  isStickiesLoaded.value = true;

  return { data, isSuccess };
};

export const retireSticky = async (id) => {
  const { axiosWrapper } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/admin/stickies/retire/${id}`,
  });

  if (isSuccess) {
    // eslint-disable-next-line no-underscore-dangle
    setActive(toValue(active).filter((sticky) => sticky._id !== id));

    if (stickiesSelectedYear === dayjs().year()) {
      getStickiesHistory({});
    }
  }

  return { isSuccess };
};

export const addSticky = async (params) => {
  const { axiosWrapper } = useFetch();

  const { isSuccess, data } = await axiosWrapper({
    type: 'post',
    url: '/admin/stickies',
    params,
  });

  if (isSuccess) {
    addActive(data);
  }

  return { isSuccess };
};

export const getStartedYear = async () => {
  const { axiosWrapper } = useFetch();

  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/admin/stickies/start-year',
  });

  if (isSuccess) {
    setStickiesStartingYear(data.stickiesStartingYear);
  }

  return { isSuccess };
};
